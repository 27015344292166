import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Pagination(theme) {
  const isDark = theme.palette.mode === "dark";
  return {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            fontWeight: theme.typography.fontWeightBold,
          },
        },
        textPrimary: {
          "&.Mui-selected": {
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.08),
            "&:hover, &.Mui-focusVisible": {
              backgroundColor: `${alpha(
                theme.palette.primary.main,
                0.24
              )} !important`,
            },
          },
        },
        outlined: {
          border: `2px solid ${theme.palette.grey[500_32]}`,
        },
        outlinedPrimary: {
          "&.Mui-selected": {
            color: isDark
              ? theme.palette.secondary.lighter
              : theme.palette.secondary.darker,
            backgroundColor: alpha(
              isDark
                ? theme.palette.primary.lighter
                : theme.palette.primary.main,
              0.08
            ),
            border: `2px solid ${alpha(
              isDark
                ? theme.palette.primary.lighter
                : theme.palette.primary.main,
              0.24
            )}`,
          },
        },
      },
    },
  };
}
