import React from "react";
import useSettings from "src/hooks/useSettings";

const useUserTheme = () => {
  const { themeMode } = useSettings();
  const isDark = themeMode === "dark";
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const bgColor = isDark ? "rgb(14 14 14 / 25%)" : "rgb(253 243 239 / 30%)";
  // const bgColor = isDark ? "rgb(96 96 96 / 45%)" : "rgb(253 243 239 / 30%)";
  return isAdmin ? "background.paper" : bgColor;
};

export default useUserTheme;
