import { GoogleOAuthProvider } from "@react-oauth/google";

import { useEffect, useMemo, useRef, useState } from "react";
import NotistackProvider from "./components/NotistackProvider";
import { ProgressBarStyle } from "./components/ProgressBar";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import ThemeColorPresets from "./components/ThemeColorPresets";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { ChartStyle } from "./components/chart";
import FloatingAction from "./components/floating-action";
import Settings from "./components/settings";
import Ternary from "./components/ternary";
import Visitor from "./components/visitor";
import { ENABLE_VISITOR_MODE, GOOGLE_CLIENT_ID } from "./config";
import useAuth from "./hooks/useAuth";
import useGetCurrency from "./layouts/shared/header/components/currency-popover/hooks/use-get-currency";
import Router from "./routes";
import { useAppConfig } from "./store/app-config";
import ThemeProvider from "./theme";
import axiosInstance from "./utils/axios";
import { getSession } from "./utils/jwt";
import LoadingScreen from "./components/LoadingScreen";
import DashboardLoadingScreen from "./components/dashboard-loading-screen";
import isPast from "date-fns/esm/isPast";
import { Backdrop, Paper, useTheme } from "@mui/material";
import { fill } from "lodash";

const fetchBrandSettings = async () => {
  try {
    const { data, status } = await axiosInstance("api/company-logos");
    if (status === 200) {
      const { logo, side_bar_logo, favicon } = data.data;
      if (logo) {
        localStorage.setItem("logo", logo);
        localStorage.setItem("side_bar_logo", logo);
        localStorage.setItem("favicon", favicon);
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export default function App() {
  const fetchCurrency = useGetCurrency();
  const { config, setConfig } = useAppConfig();
  const { getUser, user, isAdmin, isSubAdmin } = useAuth();
  useEffect(() => {
    if (user && !Object.keys(user).length) {
      getUser();
      fetchCurrency(isAdmin || isSubAdmin);
      return;
    }
  }, [user]);

  useEffect(() => {
    const fetchAppConfig = async () => {
      try {
        const { data } = await axiosInstance("api/config-settings");
        const test = data.data.reduce((acc, curr) => {
          const { code, status, value } = curr || {};

          return {
            ...acc,
            [code]: { status: Boolean(status), value },
          };
        }, {});
        setConfig(test);
      } catch (err) {
        console.error(err);
      }
    };

    fetchAppConfig();
  }, []);

  useEffect(() => {
    fetchBrandSettings();
  }, []);

  const isLoggedIn = getSession();
  const [showLoader, setIsLoaded] = useState(() => {
    return localStorage.getItem("show_loader") !== "false";
  });

  useEffect(() => {
    if (isLoggedIn) {
      setTimeout(() => {
        localStorage.setItem("show_loader", false);
        setIsLoaded(false);
      }, 3000);
    }
  }, [isLoggedIn]);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ThemeProvider>
        <ThemeColorPresets>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                <ButterflyLoader />
                {/* <Backdrop */}
                {/*   sx={(theme) => ({ */}
                {/*     color: "#fff", */}
                {/*     zIndex: theme.zIndex.drawer + 1, */}
                {/*   })} */}
                {/*   open={!isAdmin && showLoader && isLoggedIn} */}
                {/* > */}
                {/*   <Paper */}
                {/*     sx={{ */}
                {/*       width: "100%", */}
                {/*       height: "100%", */}
                {/*       borderRadius: 0, */}
                {/*       // opacity: 0.5, */}
                {/*     }} */}
                {/*   > */}
                {/*     <video */}
                {/*       width="100%" */}
                {/*       height="100%" */}
                {/*       autoPlay={!isAdmin && showLoader && isLoggedIn} */}
                {/*       muted */}
                {/*       style={ */}
                {/*         { */}
                {/*           // opacity: 0.5, */}
                {/*         } */}
                {/*       } */}
                {/*     > */}
                {/*       <source src="/video/butterfly.mp4" type="video/mp4" /> */}
                {/*       Your browser does not support the video tag. */}
                {/*     </video> */}
                {/*   </Paper> */}
                {/* </Backdrop> */}

                <Router />
                {/* <FloatingAction /> */}

                <Ternary
                  when={ENABLE_VISITOR_MODE && isLoggedIn}
                  then={<Visitor />}
                />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeColorPresets>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}
const files = {
  1280_800: "/video/1280_800.mp4",
  1920_1080: "/video/1920_1080.mp4",
  328_926: "/video/328_926.mp4",
  360_800: "/video/360_800.mp4",
  390_844: "/video/390_844.mp4",
  393_873: "/video/393_873.mp4",
  414_896: "/video/414_896.mp4",
  768_1024: "/video/768_1024.mp4",
  800_1180: "/video/800_1180.mp4",
  810_1080: "/video/810_1080.mp4",
  962_601: "/video/962_601.mp4",
};

const getVidoURI = () => {
  const currentWidthHeight = parseInt(
    `${window.innerWidth}${window.innerHeight}`
  );

  const screenWidthSize = Object.keys(files);

  const closest = {
    difference: null,
    key: null,
  };

  for (let item of screenWidthSize) {
    const absoluteDifference = Math.abs(parseInt(item) - currentWidthHeight);
    if (closest.difference === null) {
      closest.difference = absoluteDifference;
      closest.key = item;
    }

    // console.log(absoluteDifference, closest);
    if (absoluteDifference < closest.difference) {
      closest.difference = absoluteDifference;
      closest.key = item;
    }
  }

  return files[closest.key];
};
const ButterflyLoader = () => {
  const {
    palette: { mode },
  } = useTheme();
  const isDark = mode === "dark";
  const isLoggedIn = getSession();
  const [showLoader, setIsLoaded] = useState(() => {
    return localStorage.getItem("show_loader") !== "false";
  });

  const ref = useRef(null);
  useEffect(() => {
    if (isLoggedIn) {
      ref.current.play();
      setTimeout(() => {
        localStorage.setItem("show_loader", false);
        setIsLoaded(false);
      }, 4000);
    }
  }, [isLoggedIn]);

  const videoUrl = useMemo(() => {
    return getVidoURI();
  }, []);
  const { isAdmin } = useAuth();

  return (
    <Backdrop
      sx={(theme) => ({
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: isDark ? "#212B36" : "#fff",
      })}
      open={!isAdmin && showLoader && isLoggedIn}
    >
      <Paper
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          borderRadius: 0,
          overflow: "hidden", // Ensures no overflow issues
        }}
      >
        <video
          ref={ref}
          autoPlay={!isAdmin && showLoader && isLoggedIn}
          muted
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensures the video covers the entire screen
          }}
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Paper>
    </Backdrop>
  );
};
