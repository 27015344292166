// ----------------------------------------------------------------------

export default function TextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: theme.palette.mode === "dark" ? "#c1c5cf" : "#494a6fcc", // Light color in dark mode, dark color in light mode
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                theme.palette.mode === "dark" ? "#c1c5cf" : "#494a6fcc", // Border color based on theme mode
            },
            "&:hover fieldset": {
              borderColor: theme.palette.primary.main, // Hover effect
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.secondary.main, // Focused effect
              borderWidth: 2, // Optional: Thicker border when focused
            },
          },
        },
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
