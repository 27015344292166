import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const Faq = Loadable(lazy(() => import("src/pages/user/helpCenter/faq/index")));

const KnowledgeBase = Loadable(
  lazy(() => import("src/pages/user/helpCenter/knowledgeBase/index"))
);
const Questions = Loadable(
  lazy(() => import("src/pages/user/helpCenter/knowledgeBase/question/index"))
);

const ContactSupport = Loadable(
  lazy(() => import("src/pages/user/helpCenter/ticket/contact/index"))
);

const Tickets = Loadable(
  lazy(() => import("src/pages/user/helpCenter/materialTickets/index"))
);

const TicketView = Loadable(
  lazy(() => import("src/pages/user/helpCenter/ticket/viewTickets"))
);

const Mail = Loadable(
  lazy(() => import("src/pages/admin/communications/Mail/admin/index"))
);
const MailList = Loadable(
  lazy(() => import("src/pages/admin/communications/Mail/admin/mailList"))
);

const MailDetails = Loadable(
  lazy(() =>
    import("src/pages/admin/communications/Mail/subPages/MailDetails/index")
  )
);

const Compose = Loadable(
  lazy(() =>
    import("src/pages/admin/communications/Mail/components/compose/index")
  )
);
const Documents = Loadable(
  lazy(() => import("src/pages/user/helpCenter/DowloadDoc/Index.js"))
);
const Videos = Loadable(
  lazy(() => import("src/pages/user/helpCenter/video/index.js"))
);

const partOfIpe = {
  path: "ipe",
  children: [
    { index: true, element: <Navigate to="knowledge-base" /> },

    {
      path: "knowledge-base",
      children: [
        {
          index: true,
          element: <KnowledgeBase />,
        },
        {
          path: ":slug",
          element: <Questions />,
        },
      ],
    },
    { path: "documents", element: <Documents /> },
    { path: "videos", element: <Videos /> },
  ],
};

export default partOfIpe;
