import Ternary from "src/components/ternary";
import useSettings from "src/hooks/useSettings";
import Horizontal from "./components/horizontal";
import Vertical from "./components/vertical";
import backgroundImageDark from "src/images/butterfly-dark-two.webp";
import backgroundImageWhite from "src/images/butterfly-white-new.webp";
import useAuth from "src/hooks/useAuth";

const Layout = () => {
  const config = JSON.parse(localStorage.getItem("menu") || "[]");
  const { themeLayout, themeMode } = useSettings();
  const verticalLayout = themeLayout === "vertical";
  const isDark = themeMode === "dark";
  const { isAdmin } = useAuth();
  const backgroundStyle = isAdmin
    ? {}
    : {
        backgroundImage: isDark
          ? `url(${backgroundImageDark})`
          : `url(${backgroundImageWhite})`,
        backgroundSize: "cover",
        backgroundPosition: "left top",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        filter: !isDark
          ? "opacity(60%) grayscale(5%)"
          : "opacity(60%) grayscale(5%)", // Dark mode filter effect
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
      };

  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <div style={backgroundStyle}></div>

      <Ternary
        when={verticalLayout}
        then={<Vertical navConfig={config} />}
        otherwise={<Horizontal navConfig={config} />}
      />
    </div>
  );
};

export default Layout;
