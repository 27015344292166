import React from "react";

const setSymbols = (currency, list = []) => {
  const getCurrency = list.find(({ code }) => code === currency) || null;
  if (Boolean(getCurrency?.symbol)) {
    localStorage.setItem("secondary_currency", getCurrency?.symbol);
  } else {
    localStorage.setItem("secondary_currency", "");
  }
};

export default setSymbols;
