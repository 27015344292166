import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";
const ArticleCommunication = Loadable(
  lazy(() => import("src/pages/admin/communications/articles/index"))
);
const Articles = Loadable(
  lazy(() => import("src/pages/admin/communications/articles/articles/index"))
);
const ArticleCategories = Loadable(
  lazy(() =>
    import("src/pages/admin/communications/articles/article-categories/index")
  )
);
const Documents = Loadable(
  lazy(() => import("src/pages/admin/tools/documents/index"))
);
const Videos = Loadable(lazy(() => import("src/pages/admin/tools/videos")));

const partOfIpe = [
  {
    path: "ipe",
    children: [
      { element: <Navigate to="articles" />, index: true },
      {
        path: "articles",
        element: <ArticleCommunication />,
        children: [
          {
            index: true,
            element: <Articles />,
          },
          {
            path: "categories",
            element: <ArticleCategories />,
          },
        ],
      },
      { path: "documents", element: <Documents /> },
      { path: "videos", element: <Videos /> },
    ],
  },
];

export default partOfIpe;
